<template>
  <div v-if="profile.account === undefined" class="col-md-12">
    <div class="card card-container">{{ $t('logged_in') }}</div>
  </div>

  <div v-else-if="profile.account.role !== 1" class="col-md-12">
    <div class="card card-container">{{ $t('owner_only') }}
      
    </div>
  </div>

  <div v-else>
    <br />
    <p>{{ $t('program_error') }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Profile',

  computed: mapState({ profile: (state) => state.user.profile }),
  data() {
    return {
      message: '',
    }
  },
}
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
