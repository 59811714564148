<template>
  <aside>
    <div>
    <p>
    <input
      id="one"
      :value="1"
      type="radio"
      :checked="petChoice == '1'"
      @input="updateAnimalChoice($event)"
    />
    <strong>   {{ $t('dogs') }}   </strong>
    </p>
    </div>
    <div>
    <p>
    <input
      id="two"
      :value="2"
      type="radio"
      :checked="petChoice == '2'"
      @input="updateAnimalChoice($event)"
    />
    <strong>   {{ $t('cats') }}   </strong>
    </p>
    </div>
    
    <p>
    <input
      id="three"
      :value="3"
      type="radio"
      :checked="petChoice == '3'"
      @input="updateAnimalChoice($event)"
    />
    <strong>  {{ $t('birds') }}   </strong>
    </p>
    <p>
    <input
      id="three"
      :value="4"
      type="radio"
      :checked="petChoice == '4'"
      @input="updateAnimalChoice($event)"
    />
    <strong>   {{ $t('other') }}   </strong>
    </p>
    
    <div class="aside-block">

          <div class="form-group">
            <p><span for="country"><strong>{{ $t('country') }}</strong></span></p>
            <Field
              name="country"
              as="select"
              class="form-control"
              @change="updateCountry"
              v-model="selectedCountry"
            >
              <option v-for="country in countries" :value="country" :key="country">
                {{ $t(country) }}
              </option>
            </Field>
            
          </div>

          <div class="form-group">
            <p><span for="city"><strong>{{ $t('city') }}</strong></span></p>
            <Field
              name="selectedCity"
              as="select"
              class="form-control"
              v-model="selectedCity"
              @change="updateCity"
            >
              <option v-for="city in cities" :value="city.value" :key="city.value">
                {{ city.translation }}
              </option>
            </Field>
            
          </div>          

    </div>

    <div class="aside-block">
      <span style="white-space: nowrap;"><strong>{{ $t('vacation_now_and_next_month') }}</strong></span>
      <label class="checkbox-control">
        <input
          type="checkbox"
          @change="updateCreated($event)"
          :checked="checkmonth"
        />

        <div class="checkbox-box"></div>
      </label>
    </div>
    <div class="aside-block">
      <strong>{{ $t('support') }}</strong>
      <p>
        {{ $t('get_in_touch_with_us_at') }}
      </p>
      <strong><a href="#">s-nom-pethome@krucha.com</a></strong>

     
    </div>

      <p v-tooltip="`Tango Helper App`">
    
        <a href="https://tango.krucha.com" target="_blank" rel="noopener" style="color: #485fc7;" ><strong>Tango For All</strong></a>
      </p>      
  </aside>
</template>

<script>
import { Field} from 'vee-validate'

export default {
  name: 'Sidebar',
  components: {
    Field,
  },    
  data() {
    return {
      min: 0,
      max: 5000,
      isFetching: true,
      selectedCity: '',
      selectedCountry: this.$store.state.product.selectedCountry,
      countries: ['Ukraine', 'Switzerland', 'Germany', 'Spain'],
    }
  },
  computed: {
    cities() {
      return this.translateCities(this.$store.state.product.cities)
    },

    petChoice() {
      return this.$store.state.product.petChoice
    },
    pricerange() {
      return this.$store.state.product.highPrice
    },
    checkmonth() {
      return this.$store.state.product.createdAt
    },
    message() {
      return this.$store.state.product.message
    },
  },
  methods: {
    //async getCities() {
    // await this.$store.state.product.cities
    //},
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 
      return filledCities
    },    
    changeAnimals() {
      try {
        this.$store.dispatch('getAnimals').then(() => {
        })
      } catch (err) {
        throw new Error(err)
      }
    },
    updateCountry($event) {
      this.$store.commit('setSelectedCountry', $event.target.value)
      this.$store.commit('setSelectedCity', '')
      
      this.$store.commit('setCreatedAt', true)
      this.$store.dispatch('getCities').then(() => {
        //this.cities = this.$store.state.product.cities
        this.changeAnimals()
      })
    },
    updateCity($event) {
      this.$store.commit('setSelectedCity', $event.target.value)
      this.changeAnimals()
    },
    updateAnimalChoice($event) {
      this.$store.commit('setPetChoice', $event.target.value)
      this.$store.commit('setCreatedAt', true)
      this.$store.commit('setSelectedCity', this.$store.state.product.selectedCity)
      this.animals = this.$store.dispatch('getAnimals')
    },
    updateHighPrice($event) {
      this.$store.commit('setHighPrice', $event.target.value)
    },
    updateCreated($event) {
      this.$store.commit('setCreatedAt', $event.target.checked)
      this.animals = this.$store.dispatch('getAnimals')
    },
    currentDate() {
      const current = new Date()
      const date =
        current.getFullYear() +
        '-' +
        (current.getMonth() + 1) +
        '-' +
        current.getDate()
      // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      // const dateTime = date +' '+ time;
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      current.setDate(new Date(date).getDate() - 7)
      return this.$store.state.product.formatDate(current)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
  },
  created() {
    console.log('created Sidebar')
    try {
      //this.$store.commit('setCreatedAt', true)
      this.$store.dispatch('getCities').then(() => {
        this.translateCities(this.$store.state.product.cities)
        //this.cities = this.$store.state.product.cities
        if (this.$store.state.product.cities) {
          this.isFetching = false
        }

        this.$store.dispatch('getAnimals')
      })
    } catch (err) {
      throw new Error(err)
    }
  },
}
</script>

<style lang="css">
.aside-block {
  padding: 40px 0;
  border-bottom: 1px solid #eee;
  font-size: 15px;
}
.min {
  float: left;
}
.max {
  float: right;
}
.aside-block h4 {
  margin-bottom: 10px;
}
.checkbox-control {
  position: relative;
  display: inline-block;
}
.checkbox-box {
  width: 18px;
  height: 18px;
  border: 2px solid #131313;
  position: relative;
  cursor: pointer;
  float: left;
  top: 2px;
  margin-right: 10px;
}
input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
input[type='checkbox']:hover ~ .checkbox-box,
input[type='checkbox']:focus ~ .checkbox-box,
input[type='checkbox']:checked ~ .checkbox-box {
  border-color: #5044ff;
}
input[type='checkbox']:checked ~ .checkbox-box::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
  background: #5044ff;
}
</style>
