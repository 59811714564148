import { createRouter, createWebHashHistory } from 'vue-router'
import CartList from '../components/cart/Cart_List.vue'
import AnimalList from '../components/product/AnimalList.vue'
import TakerList from '../components/product/TakerList.vue'
import Note from '../components/product/Note.vue'
import ProfileContact from '../components/product/ProfileContact.vue'
import ProfilePet from '../components/product/ProfilePet.vue'
import SideMainLayout from '../components/core/SideMainLayout.vue'
import SidePersonLayout from '../components/core/SidePersonLayout.vue'
import Login from '../components/login/Login'
import ForgotPassword from '../components/login/ForgotPassword'
import ChangePassword from '../components/login/ChangePassword'
import Register from '../components/login/Register'
import Account from '../components/account'
import Error from '../components/account/Error'
import Profile from '../components/account/Profile'
import PetProfile from '../components/account/PetProfile'
import PetProfileEdit from '../components/account/PetProfileEdit'
// import NavbarPet from '../components/core/Navbar_Pet.vue';
//import Home from '../views/Home.vue'
import store from '../store'
import i18n from '../i18n'

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`)
}

const routes = [
  {
    path: `/`,
    name: 'index',
    component: loadView('Home'),
    
  },  
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
  },
  {
    path: '/animals/pet/:id',
    name: 'ProfilePet',
    component: ProfilePet,
    beforeEnter: (to, from, next) => {
      console.log(`${from.path} to ${to.path}?`)
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },  
  {
    path: '/animals/owner/:id',
    name: 'ProfileContact',
    component: ProfileContact,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/taker/:id',
    name: 'TakerList',
    component: TakerList,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: (to, from, next) => {

      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/notes/:id',
    name: 'see-thank-details',
    component: Note,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/leavenote/:id',
    name: 'thank-details',
    component: Note,
    beforeEnter: (to, from, next) => {
      if (store.getters.isGiver || store.getters.isBoth) {
        next()
        return
      } else next('/error')
    },
  },
  {
    path: '/profile/:id',
    name: 'profile-edit-details',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/accounts/:id',
    name: 'account-edit-details',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      } else next('/login')
    },
  },
  {
    path: '/pet',
    name: 'pet-profile-details',
    component: PetProfile,
    beforeEnter: (to, from, next) => {
      console.log('store.getters.isGiver', store.getters.isGiver)
      if (store.getters.isAuthenticated && (store.getters.isGiver || store.getters.isBoth)) {
        next()
      } else next('/error')
    },
  },
  {
    path: '/pets/:id',
    name: 'pet-edit-details',
    component: PetProfileEdit,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated && (store.getters.isGiver || store.getters.isBoth)) {
        next()
      } else next('/error')
    },
  },
  {
    path: '/petchoice',
    component: SideMainLayout,
    children: [
      {
        path: '/petchoice',
        component: AnimalList,
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next()
          } else next('/error')
        },
      },
    ],
  },
  {
    path: '/takers',
    component: SidePersonLayout,
    children: [
      {
        path: '/takers',
        component: TakerList,
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next()
          } else next('/error')
        },
      },
    ],
  },
  {
    path: '/cart',
    component: CartList,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',  
})

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next();
//     return;
//   }
//   next("/login");
// };

router.beforeEach((to, from) => {
  const newLocale = to.params.locale
  const prevLocale = from.params.locale
  // If the locale hasn't changed, do nothing
  if (newLocale === prevLocale) {
    return
  }
  i18n.setLocale(newLocale)
})

export default router
