<template>
  <input
    type="file"
    accept="image/*"
    @change="imagesSelected"
    multiple
    ref="uploadPhotos"
    :placeholder="placeholder"
    class="form-control"
    name="photo"
  />

  <!--<img :src="photoImg" id="photo_preview">-->
</template>

<script>

import i18n from '@/i18n'

export default {
  name: 'photo-upload',
  props: ['photoType', 'placeholder', 'er'],
  data() {
    return {
      photos: [],
      photoImg: null,
      allowableTypes: ['jpg', 'jpeg', 'png', 'gif'],
    }
  },
  methods: {
    async imagesSelected(event) {
      let files = [...event.target.files]
      if (files && files.length == 0) {
        this.$emit('changeMessage', i18n.global.t('file_required') )
        this.photos = []
        return        
      }
      if (
        !this.allowableTypes.includes(files[0].name.split('.').pop().toLowerCase())
      ) {
        this.$emit('changeMessage', i18n.global.t('image_types_expected').concat(' ').concat(this.allowableTypes.join("|").toUpperCase()))
        this.photos = []
        return
      }
      this.photos = await Promise.all(
        files.map((f) => {
          return this.readPhoto(f)
        })
      )
      //const preview = document.querySelector('photo_preview')
      //preview.src = this.photos[0].data
      this.$emit('changeMessage', '')
    },
    readPhoto(file) {
      return new Promise((resolve) => {
        console.log('loading file', file.name, '...')
        let fileReader = new FileReader()
        fileReader.onload = () => {
          if (fileReader.result.startsWith('data:image/')) {
            let image = new Image()
            image.onload = () => {
              // let mw = 1600
              // let mh = 1200
              let mw = 200
              let mh = 150
              if (image.width < image.height) {
                //mw = 1200
                // mh = 1600
                mw = 200
                mh = 150
              }
              if (image.width > mw) {
                let d = image.width - mw
                let prw = image.width / 100
                let prh = image.height / 100
                let pr = 100 - (d / image.width) * 100
                image.width = prw * pr
                image.height = prh * pr
              }
              if (image.height > mh) {
                let d = image.height - mh
                let prw = image.width / 100
                let prh = image.height / 100
                let pr = 100 - (d / image.height) * 100
                image.width = prw * pr
                image.height = prh * pr

              }
              const canvas = document.createElement('canvas')
              canvas.width = image.width
              canvas.height = image.height
              const ctx = canvas.getContext('2d')
              ctx.drawImage(image, 0, 0, image.width, image.height)

              let imgdata = canvas.toDataURL('image/jpeg')

              resolve({
                data: imgdata,
                name: file.name,
                type: this.photoType,
              })
            }
            image.src = fileReader.result
          } else {
            resolve({
              data: fileReader.result,
              name: file.name,
              type: this.photoType,
            })
          }
        }
        fileReader.readAsDataURL(file)
      })
    },
  },
}
</script>
