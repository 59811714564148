export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Pet Temporary Home Near Me..."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app was made to help people to find a temporary vacation home for their pets. Owners, are those who own pet(s), they have to sign up themselves first and after to add their pets to their profile. Foster owner, who loves animals and can take pet to his/her place where pet is comfortable and happy, foster owners are taking responsibilities of a pet during the whole stay, and foster owners should be capable of doing all of those necessary services (walking outside with pet, feeding, brushing...) which owner of a pet has asked to do. For services, an owner of a pet adds need for home for her/his pet with dates when home is needed and puts award which will be given to foster owner for the service or without award with just given by owner food for a pet. Foster owners who are taking pets can do it if they love animals and do not have yet their own pet to gain an experience and feeling if they can be the owners. Foster owners must sign in also. Owners have to write a note (review) about foster owner who took their pet, to write about if it was good or bad service for their pet with an explanation. Owner can find a Foster owner by location(country/city) by phone, by email. Then to email to her/him asking for a service for a pet. Foster owner can find a pet by location(contry/city) and email to owner of a pet about interess and readiness to a take pet to his/her place. Owner, pet and Foster owner can meet to see if all conditions are agreeable for all. This app is for a building friendly community of people who loves animals."])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "home_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/Home_page_en_small_bright1.png"])},
  "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets"])},
  "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet"])},
  "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foster family"])},
  "takers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foster family"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "choose_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])},
  "your_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "profile_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid profile photo"])},
  "profile_photo_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo for your profile"])},
  "profile_photo_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo of your pet"])},
  "field_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title is required"])},
  "use_another_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use another photo"])},
  "ask_admin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask admin help"])},
  "ph_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers, no more then 10"])},
  "title_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr./Ms./Miss"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "pswd_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be more then 6 chars"])},
  "taker_owner_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foster family takes a pet from Owner for a period of time"])},
  "owner_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner, Foster or Both"])},
  "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
  "both_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Foster family"])},
  "file_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File required"])},
  "password_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is taken"])},
  "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is taken"])},
  "default_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
  "logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to be logged in first"])},
  "owner_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to be an owner of a pet for these actions"])},
  "program_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program error please contact to admin"])},
  "new_pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add pet"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  from  "])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  to  "])},
  "service_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Costs/Award from Owner"])},
  "add_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add request for"])},
  "description_need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of need"])},
  "price_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award for all period"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "needed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needed for"])},
  "pet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet name"])},
  "physical_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical characteristics"])},
  "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs"])},
  "please_fill_out_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, fill out fields"])},
  "sorry_you_can_only_upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry you can only upload files: "])},
  "image_types_expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image types expected:"])},
  "pet_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet's habits"])},
  "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habits"])},
  "Ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
  "Germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
  "Spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
  "Switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
  "Kyiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyiv"])},
  "Odesa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesa"])},
  "Kharkiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kharkiv"])},
  "Lviv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lviv"])},
  "Madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
  "Barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
  "Munchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munchen"])},
  "Lugano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugano"])},
  "Locarno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locarno"])},
  "Bern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bern"])},
  "Zurich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurich"])},
  "dogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Dogs  "])},
  "birds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Birds  "])},
  "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Cats  "])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Other  "])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "get_in_touch_with_us_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with us at"])},
  "vacation_now_and_next_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now and next month"])},
  "date_for_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates when home needed"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "email_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at least 3 chars"])},
  "no_pets_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pets yet"])},
  "no_takers_for_your_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Foster family for your selection yet"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "leave_thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave thank you note for "])},
  "see_thank_you_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See thank you notes for "])},
  "wrong_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong dates"])},
  "description_need_req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of need, required"])},
  "edit_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your pet profile"])},
  "add_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your pet profile"])},
  "edit_your_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your profile"])},
  "thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you note(s) for"])},
  "thank_you_empty_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thank you note(s) yet for"])},
  "description_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "from_someone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" not found"])},
  "kind_of_pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of pet"])},
  "kind_of_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind of pet (dog, cat, bird for the rest choose 'Other')"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "confirmed_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed password"])},
  "invalid_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid request"])},
  "cannot_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not change password"])},
  "error_sending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending email"])},
  "error_with_typed_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error with typed credentials"])},
  "email_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])},
  "delete_my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete profile"])},
  "delete_profile_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete permanently your profile with all your profile data"])},
  "email_forget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password will be sent to your email(check all folders)"])}
}