<template>
  <div class="row">
    <div class="col">
    <p ><strong>{{ taker.title }} </strong></p >
      <p ><strong>{{ taker.name }} </strong></p >
      <img :src="getImgPhoto(taker)" style="padding-left: 3px" />
    </div>
  </div>
  <div class="row">      
    <div class="col">
     <strong style="font-size:110%;">{{ $t('your_interests') }}</strong> {{ taker.interests }} <br>
      <strong style="font-size:110%;">{{ $t('city') }}</strong> {{ taker.city }}<br>
      <strong style="font-size:110%;">{{ $t('email') }}</strong> {{ taker.email }}<br>
      <strong style="font-size:110%;">{{ $t('phone') }}</strong> {{ taker.phone }}
      
      <p><span v-if="(isGiver || isBoth) && pid != taker.id">
        <router-link :to="'/leavenote/' + taker.id"
          >{{ $t('leave_thank_you_note_for') }} {{ taker.title }} {{ taker.name }}</router-link
        >
      </span>
      </p>
      <p><span>
        <router-link :to="'/notes/' + taker.id"
          >{{ $t('see_thank_you_notes') }} {{ taker.title }} {{ taker.name }}</router-link
        > </span>
      </p>
    </div>
    
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Taker',
  computed: {
    ...mapGetters(['isGiver', 'isBoth']),
  },
  props: ['taker'],
  data() {
    return {
      notes: [],
      pid: this.$store.state.user.profile.id,
    }
  },

  methods: {
    getImgPhoto(obj) {
      return obj.photo
    },
  },
  created() {},
}
</script>
