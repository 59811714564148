<template>
  <div v-if="Array.isArray(takers) && takers[0]">
    <div class="card" v-for="taker in pageOfItems" :key="taker.id">
      <Taker :taker="taker" />
    </div>
    <div class="card">
      <JwPagination :items="takers" @changePage="onChangePage"></JwPagination>
    </div>
  </div>
  <div v-else>
    <p>{{ $t('no_takers_for_your_selection') }}</p>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import Taker from './Taker'
import JwPagination from '../core/JwPagination'
// import NavbarPeople from '@/components/core/NavbarPeople';

export default {
  name: 'TakerList',
  components: {
    // NavbarPeople,
    Taker,
    JwPagination,
  },
  data() {
    return {
      // test: 100,
      // message: this.$store.state.product.message
      pageOfItems: [],
    }
  },
  computed: {
    // ...mapGetters([
    //   'productItems'
    // ])
    takers() {
      let takers = this.$store.state.product.takers
      return takers
    },
  },
  methods: {
    getImgPhoto(obj) {
      return obj.photo
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
  },
  created() {
    this.$store.commit('setSelectedCity', '')
  },
}
</script>

<style>
.card {
  background-color: #2ed87c5c !important;
}
</style>
