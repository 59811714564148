import axios from 'axios'
import router from '@/router'
import i18n from '@/i18n'
const state = {
  productItems: [],
  highPrice: 0,
  createdAt: false,
  petChoice: 1,
  animals: [],
  takers: [],
  owner: {},
  pet: {},
  taker: {},
  notes: [],
  cities: [],
  selectedCountry: i18n.global.t('default_country'),
  selectedCity: '',
  message: '',
}

const mutations = {
  UPDATE_PRODUCT_ITEMS(state, payload) {
    state.productItems = payload
  },
  UPDATE_ANIMALS(state, payload) {
    state.animals = payload
    state.message = ''
  },
  UPDATE_OWNER(state, payload) {
    state.owner = payload
    state.message = ''
  },
  UPDATE_TAKER_NOTES(state, payload) {
    state.notes = payload
    state.message = ''
  },
  UPDATE_TAKER(state, payload) {
    state.taker = payload
    state.message = ''
  },
  setPet(state, payload) {
    state.pet = payload
  },  
  setHighPrice(state, event) {
    state.highPrice = event
  },
  setMessage(state, event) {
    state.message = event
  },
  setSelectedCountry(state, event) {
    state.selectedCountry = event
  },
  setSelectedCity(state, event) {
    state.selectedCity = event
  },
  setPetChoice(state, event) {
    state.petChoice = event
  },
  setCreatedAt(state, event) {
    state.createdAt = event
    router.push('/petchoice')
  },
  UPDATE_CITIES(state, payload) {
    state.cities = payload
  },
  UPDATE_TAKERS(state, payload) {
    state.takers = payload
  },
}

const actions = {
  async getCities({ commit }) {
    await axios
      .get(`/api/animals/cities/` + state.selectedCountry)
      .then((response) => {
        commit('UPDATE_CITIES', response.data)
        // commit('setSelectedCity', state.cities[0])
      })
  },
  async getPetById({ commit }, id) {
    await axios
      .get('/api/animals/pet/' + JSON.stringify(id))
      .then((response) => {
        let pet = response.data
        // pet.id = response.data.id
        commit('setPet', pet)
        
        // router.push("/profileContact/");
      })
      
  },
  async getOwner({ commit }, id) {
    await axios
      .get('/api/animals/owner/' + JSON.stringify(id))
      .then((response) => {
        let owner = response.data.account
        owner.id = response.data.id
        commit('UPDATE_OWNER', owner)
        // router.push("/profileContact/");
      })
  },
  async getNotes({ commit }, id) {
    await axios.get('/api/notes/' + JSON.stringify(id)).then((response) => {
      commit('UPDATE_TAKER_NOTES', response.data)
    })
  },
  async getTaker({ commit, dispatch }, id) {
    //const runResult = await new Promise((resolve, reject) => {
    try {
      axios.get('/api/taker/' + JSON.stringify(id)).then((response) => {
        let taker = response.data.account
        taker.id = response.data.id
        commit('UPDATE_TAKER', taker)
        dispatch('getNotes', taker.id)
      })
    } catch (error) {
      console.log('getTaker error', error)
      //reject(error);
    }
    //})
    //return runResult
  },
  async getAnimals({ commit }) {
    let search = {
      city: state.selectedCity,
      country: state.selectedCountry,
      kind: state.petChoice,
      from: state.createdAt,
    }
    await axios
      .get(`/api/animals/` + JSON.stringify(search))
      .then((response) => {
        commit('UPDATE_ANIMALS', response.data)
      })
  },
  async getTakers({ commit }) {
    let location = {
      city: state.selectedCity,
      country: state.selectedCountry,
    }
    await axios
      .get(`/api/takers/` + JSON.stringify(location))
      .then((response) => {
        commit('UPDATE_TAKERS', response.data)
      })
  },
  saveNote({ rootState , commit}, note) {
    let owner_id = rootState.user.profile.id
    note.owner_id = owner_id
    note.note = note.note.replace(/\\n/g, ' ')
    note.owner_name = rootState.user.profile.account.title.concat(
      ' ',
      rootState.user.profile.account.name
    )
    
    axios.post(`/api/savenote/` + encodeURIComponent(JSON.stringify(note))).then((response) => {
      commit('UPDATE_TAKER_NOTES', response.data)
    })
    //router.push('/taker/'+ JSON.stringify(note.taker_id));
    router.push('/takers/')
  },
  leaveNote(id) {
    router.push('/leavenote/' + JSON.stringify(id))
  },
  async search({ commit }, acc) {
    const runResult = await new Promise((resolve, reject) => {
      try {
        axios
          .get(`/api/search/takers/` + JSON.stringify(acc))
          .then((response) => {
            commit('UPDATE_TAKERS', response.data)
            resolve(true)
          })
      } catch (error) {
        reject(error)
      }
    })
    return runResult
  },
}

const getters = {
  animals: (state) => state.animals,
  takers: (state) => state.takers,
  notes: (state) => state.notes,
  taker: (state) => state.taker,
  pet: (state) => state.pet,
  choice: (state) => state.taker,
  cities: (state) => state.petChoice,
  selectedCity: (state) => state.selectedCity,
  selectedCountry: (state) => state.selectedCountry,
  selectedEmail: (state) => state.selectedEmail,
  selectedPhone: (state) => state.selectedPhone,
  productItems: (state) => state.productItems,
  productItemById: (state) => (id) => {
    return state.productItems((productItem) => productItem.id === id)
  },
}

const productModule = {
  state,
  mutations,
  actions,
  getters,
}

export default productModule
