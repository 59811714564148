<template>
  <div class="col-md-12">
    <div class="card card-container" v-if="showNotes && !isFetching">
      <div v-if="Array.isArray(notes) && notes[0]">
        <strong
          >{{ $t('thank_you_note_for') }} {{ taker.title }} {{ taker.name }}</strong
        >
        <div v-for="el in notes" :key="el.id">
          <span><strong>... {{ $t('from_someone') }} {{ el.owner_name }}</strong></span> 
            
          
          <p style="font-size: 100%;"><strong>{{ el.created }}:</strong> {{ el.note }} </p> 
        </div>
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
      </div>
      <div v-else>
        <strong
          >{{ $t('thank_you_empty_note_for') }} {{ taker.title }}
          {{ taker.name }}</strong
        >
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
      </div>
    </div>
  </div>

  <div v-if="showForm && !isFetching" class="card card-container">
    <strong>{{ $t('thank_you_note_for') }}  {{ taker.title }} {{ taker.name }}</strong>
    <Form @submit="onSubmit">
      <div class="form-group">
        <strong><label for="note">{{ $t('description_note') }}:</label></strong>
        <textarea row="3"
          class="form-control"
          id="note"
          v-model="note"
          name="note"
        />
      </div>
      <div class="form-group">
        
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click.stop.prevent="cancel">
          {{ $t('cancel') }}
        </button>
        <button type="submit" class="badge badge-success mr-2" style="font-size: .87em;">{{ $t('save') }}</button >
      </div>
    </Form>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { Form} from 'vee-validate'
export default {
  name: 'Note',
  components: {Form},
  data() {
    return {
      id: '',
      note: '',
      showNotes: false,
      showEmpty: false,
      isFetching: true,
      message: '',
    }
  },
  ...mapGetters(['notes']),
  computed: {
    ...mapState({ taker: (state) => state.product.taker }),
    notes() {
      return this.$store.state.product.notes
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1)
      return
    },
    formatDate() {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date()
      )
    },
    async onSubmit() {
      try {
        let note = {
          taker_id: this.id,
          note: this.note,
          created: this.formatDate()
        }
        if (this.note && this.note.length > 0){
          this.$store.dispatch('saveNote', note).then(() => {
          })
          return
        }
        else return false
      } catch (err) {
        throw new Error(err)
      }
    },
  },

  async created() {
    this.id = this.$route.params.id

    this.showNotes = this.$route.path.includes('notes')
    this.showForm = !this.$route.path.includes('notes')

    try {
      await this.$store.dispatch('getTaker', this.$route.params.id).then(() => {
        if (this.$store.state.product.taker) {
          this.isFetching = false
        }
      })
    } catch (err) {
      throw new Error(err)
    }
  },
}
</script>
