<template>
  <nav class="navbar navbar-default navbar-fixed-top" style="background-color:#d411ce40;">
    <div id="navbar" class="navbar-collapse">
      <div >
        <div class="navbar-item">
          <div class="buttons">
            <router-link to="/" class="button" id="home_button" style="font-size: 110%;">
              <strong> {{ $t('home') }}</strong>
            </router-link>
            <router-link to="/petchoice" class="button" id="pets_button" style="font-size: 110%;">
              <strong> {{ $t('pets') }}</strong>
            </router-link>
            
            <router-link to="/takers" class="button " id="takers_button" style="font-size: 110%;">
              <strong> {{ $t('takers') }}</strong>
            </router-link>
            
            <LocaleSwitcher />
          </div>
        </div>
         
      </div>
      <div > 
            <div v-if="isProfileLoaded" class="element">
              
                <router-link to="/account"><strong>{{ name }}  <i class="fas fa-eye fa-1x blue-color"></i></strong></router-link>
                <!--<i class="fa fa-user fa-1x blue-color"></i>-->
                
                
              
                          <span  v-if="isAuthenticated" @click="logout" >
                  <router-link to="/" class="nav-link"><strong> {{ $t('logout') }}</strong> </router-link>
                </span>                

            </div>
            <div v-if="isProfileLoaded" class="element">

            </div>
            <div v-if="!isAuthenticated && !authLoading" class="element">
              
                <router-link to="/login" class="nav-link">
                  <i class="fa fa-user fa-1x blue-color"></i>
                  {{ $t('login') }}</router-link
                >
              
          
            </div>
            <div v-if="!isAuthenticated && !authLoading" class="element">
                <span>
                <router-link to="/register" class="nav-link">
                  <i class="fa fa-user blue-color fa-1x"></i>{{ $t('signup') }}
                </router-link>
              </span>
            </div>  
            <div class="element-img" >       
              <img id="logo-img" name="logo" alt="Logo" src="/assets/logo2.png" fetchpriority="high" width="120" height="130"/>
            </div> 
      

      </div>
  
    </div>

  </nav>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import LocaleSwitcher from '@/views/LocaleSwitcher';
import { AUTH_LOGOUT } from '/static/actions/auth'
export default {
  name: 'NavbarPet',
  components: { LocaleSwitcher },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
    },
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
    ...mapState({
      authLoading: (state) => state.auth.status === 'loading',
      name: (state) =>
        `${state.user.profile.account.title} ${state.user.profile.account.name}`,
    }),
  },
  created() {
  },
}


</script>

<style scoped>
.button {
  
    background: lightyellow;

}
.router-link-exact-active.button {
  
    background: lightblue;
 
  
}
.exact-active-link.button {
    
  
    background: lightblue
  
}
</style>      
