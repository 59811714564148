<template>
  <div style="height: 100% !important;">
  <NavbarPet />
  <div class="container mt-12" style="background-color:#ffe08a54;height: 100%;">
    
      <div class="column is-12 column--align-center" >
        <router-view> </router-view>
      </div>
    
  </div>
  </div>
</template>
<script>
// import Home from "@/views/Home.vue";
import NavbarPet from '@/components/core/Navbar_Pet'

export default {
  name: 'App',
  components: {
    NavbarPet,
  },
}
</script>

<style>
body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  
  background-color: #ffe08a54 !important;
}
 
</style>




