<template>
  <aside>

    <div >
      
        <Form @submit="search" >

          <div class="form-group">
            <label for="country"><strong>{{ $t('country') }}</strong></label>
            <Field
              name="country"
              as="select"
              class="form-control"
              @change="updateCountry"
              v-model="selectedCountry"
            >
              
              <option v-for="country in countries" :value="country" :key="country">
                {{ $t(country) }}
              </option>
            </Field>
            
          </div>

          <div class="form-group">
            <label for="city"><strong>{{ $t('city') }}</strong></label>
            <Field
              name="selectedCity"
              as="select"
              class="form-control"
              v-model="selectedCity"
              @change="updateCity"
            >
              <option v-for="city in cities" :value="city.value" :key="city.value">
                {{ city.translation }}
              </option>
            </Field>
            
          </div>      


          <div class="form-group">
            <span name="email"  v-tooltip="`${$t('email_tip')}`"><strong>{{ $t('email') }}</strong></span>
            <Field name="email" type="text" v-model="email" @change="updateError" class="form-control" rules="min:3"/>
            <ErrorMessage name="email"  class="error-feedback"/>

            
          </div>
          <div class="form-group">
            <span name="phone"  v-tooltip="`${$t('ph_tip')}`"><strong>{{ $t('phone') }}</strong></span>
            <Field name="phone" type="number" v-model="phone" @change="updateError" class="form-control" rules="min:3" />
            <ErrorMessage name="phone" class="error-feedback" />
          </div>  


          <span class="input-group-btn" @click="search()">
            <button class="badge badge-dark" style="font-size: .87em;" type="submit" id="btn-search">
              {{ $t('search') }}
            </button>
          </span>
        </Form>
      
    </div>
    <div class="aside-block">
      <strong>{{ $t('support') }}</strong>
      <p>
        {{ $t('get_in_touch_with_us_at') }} 
      </p>
      <strong><a href="#">s-nom-pethome@krucha.com</a></strong>

  
    </div>
      <p v-tooltip="`Tango Helper App`">
    
        <a href="https://tango.krucha.com" target="_blank" rel="noopener" style="color: #485fc7;" ><strong>Tango For All</strong></a>
      </p>      
  </aside>
</template>

<script>
//import i18n from '@/i18n'
import { directive } from 'vue-tippy'
import { required, min, email} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

export default {
  name: 'SidebarPerson',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  directives: {
    tippy: directive,
  },  
  data() {
    return {
      min: 0,
      max: 5000,
      isFetching: true,
      email: '',
      phone: '',
      selectedCity: '',
      selectedCountry: this.$store.state.product.selectedCountry,
      countries: ['Ukraine', 'Switzerland', 'Germany', 'Spain'],
    }
  },
  computed: {
    cities() {
      return this.translateCities(this.$store.state.product.cities)
    },
  },
  methods: {
    updateError() {
      if (this.email.length > 0 && this.email.length < 4) {
        return
      }
      if (this.phone.length > 0 && this.phone.length < 4) {
        return
      }
      this.search() 
    },
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 
      return filledCities
    }, 
    getError() {
      return this.$store.state.profiles.message
    },
    async search() {
      try {
        let acc = {
          email: this.email,
          phone: this.phone,
          city: this.$store.state.product.selectedCity,
          country: this.$store.state.product.selectedCountry,
        }
        await this.$store.dispatch('search', acc).then(() => {})
      } catch (err) {
        throw new Error(err)
      }
    },
    changeTakers() {
      try {
        let location = {
          city: this.$store.state.product.selectedCity,
          country: this.$store.state.product.selectedCountry,
        }

        this.$store.dispatch('getTakers', location).then(() => {
        })
      } catch (err) {
        throw new Error(err)
      }
    },
    updateCountry($event) {
      this.$store.commit('setSelectedCountry', $event.target.value)
      this.$store.commit('setSelectedCity', '')

      // this.phone = ''
      // this.email = ''
      this.$store.dispatch('getCities').then(() => {
        //this.cities = this.$store.state.product.cities 
        this.changeTakers()
      })
    },
    updateCity($event) {
      this.$store.commit('setSelectedCity', $event.target.value)
      // this.phone = ''
      // this.email = ''
      // this.changeTakers()
    },
  },
  created() {
    try {
      
      //this.$store.commit('setSelectedCountry', i18n.global.t('default_country'))
      this.$store.dispatch('getCities').then(() => {
        //this.cities = this.$store.state.product.cities 
        //this.translateCities(this.$store.state.product.cities) 
        if (this.$store.state.product.cities) {
          this.isFetching = false
        }
        this.$store.dispatch('getTakers')
      })
      
    } catch (err) {
      throw new Error(err)
    }
  },
}
</script>

<style lang="css">
.aside-block {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  font-size: 15px;
}
.min {
  float: left;
}
.max {
  float: right;
}
.aside-block h4 {
  margin-bottom: 10px;
}
.checkbox-control {
  position: relative;
  display: inline-block;
}
.checkbox-box {
  width: 18px;
  height: 18px;
  border: 2px solid #131313;
  position: relative;
  cursor: pointer;
  float: left;
  top: 2px;
  margin-right: 10px;
}
input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
input[type='checkbox']:hover ~ .checkbox-box,
input[type='checkbox']:focus ~ .checkbox-box,
input[type='checkbox']:checked ~ .checkbox-box {
  border-color: #5044ff;
}
input[type='checkbox']:checked ~ .checkbox-box::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
  background: #5044ff;
}
</style>
