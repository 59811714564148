<template>
  <div>
    <div class="headline">
      <p>
        <span v-if="isGiver || isBoth"
          ><router-link :to="'/pet/'" class="badge badge-warning" style="font-size: .87em;"
            >{{ $t('new_pet') }}</router-link
          ></span
        >
      </p>
      
      <div class="pet-list">
        <div class="container is-fluid">
          <div
            v-if="Array.isArray(profile.pets) && profile.pets[0]"
            class="tile is-ancestor"
          >
            <div
              class="tile is-parent"
              v-for="pet in profile.pets"
              :key="pet._id">
            

              <div v-if="!pet.need || !pet.need.need_id">
                  <p @click="newNeed(pet)"
                    ><router-link to="/account" class="badge badge-warning" style="font-size: .87em;"
                      >{{ $t('add_request') }} {{ pet.name }}</router-link
                    ></p>
              </div>
              <div v-if="pet && pet.name">
                <strong>{{ pet.name }}</strong>

                <img :src="getImgPhoto(pet)" style="padding-left: 7px" />

                <p>
                  <router-link
                    :to="'/pets/' + pet.id"
                    class="badge badge-success mr-2"
                    >{{ $t('edit') }}</router-link
                  >
                  <span @click="deletePet(pet)"
                    ><router-link to="/account" class="badge badge-danger"
                      >{{ $t('delete') }}</router-link
                    ></span
                  >
                </p>
              </div>
              <div v-if="pet">
                <div v-if="pet.need && pet.need.need_id">
                  <p><strong>{{ $t('needed_for') }} {{ pet.name }}:</strong></p>
                  <strong>{{ pet.need.title }}</strong>
                  <span @click.stop.prevent="editNeed(pet)" >
                    <a href="#" style="margin-right: 10px;margin-left: 5px;">
                      <i class="fa fa-edit fa-1x green-color" ></i>
                    </a>
                  </span>
                  <span @click="deleteNeed(pet)">
                    <a href="#" >
                      <i class="fa fa-trash fa-1x red-color"></i>
                    </a>
                  </span>
                  <p>
                    <strong
                      >{{ $t('from') }}: {{ this.formatDate(pet.need.from_date) }} - {{ $t('to') }}:
                      {{ this.formatDate(pet.need.to_date) }}
                    </strong>
                  </p>
                  <span>
                    <span v-if="pet.need.vacation_bid_price === 0"
                      ><strong
                        >{{ $t('service_costs') }}
                      </strong>
                    </span>
                  </span>
                  <p>
                    <span v-if="pet.need.vacation_bid_price !== 0"
                      ><strong
                        >{{ $t('service_costs') }}: {{ pet.need.vacation_bid_price }}
                      </strong>
                    </span>
                  </p>
                </div>

                <div  v-if="pet.need && showForm && pet.id === currentPet.id"  class="edit-form" >
                
                  <Form @submit="addNeed(currentPet)" ref="needForm">
                    
                    <div class="form-group">
                      <label for="title"><strong>{{ $t('description_need') }} </strong>
                        <i class="fa fa-asterisk fa red-color"></i>
                      </label>
                      <Field :validateOnChange="true" :validateOnInput="true"
                        class="form-control" type="text"
                        id="title"
                        v-model="currentPet.need.title"
                        name="title" rules="required|min:3"
                      />
                      <ErrorMessage name="title" class="error-feedback" />
                    </div>

                    <div class="form-group" style="padding-left:3px;">
                      <label for="from_date"><strong>{{ $t('from') }}</strong>
                        <i class="fa fa-asterisk fa red-color"></i>
                      </label>
                      <datepicker v-model="currentPet.need.from_date" />
                      <input
                        type="hidden"
                        name="currentPet.need.from_date"
                        v-model="currentPet.need.from_date"
                      />
                    </div>

                    <div class="form-group" style="padding-left:3px;">
                      <label for="to_date"><strong>{{ $t('to') }}</strong>
                        <i class="fa fa-asterisk fa red-color"></i>
                      </label>
                      <datepicker v-model="currentPet.need.to_date" />
                      <input
                        type="hidden"
                        name="currentPet.need.to_date"
                        required
                        v-model="currentPet.need.to_date"
                      />
                    </div>

                    <div class="form-group">
                      <label for="price"><strong>{{ $t('price_pay') }}</strong></label>
                      <input
                        class="form-control"
                        id="price"
                        v-model="currentPet.need.vacation_bid_price"
                        name="price"
                        type="number"
                        min="0"
                      />
                    </div>

                    <div>
                      <p>
                        <span class="input-group-btn" >
                          <button
                            class="badge badge-warning mr-2"
                            @click="cancelUpdate(currentPet)"
                          >
                            {{ $t('cancel') }}
                          </button>

                          <button type="submit"
                            class="badge badge-success"
                            
                            id="messagebtn-add"
                          >
                            {{ $t('save') }}
                          </button>
                        </span>
                      </p>
                    </div>
                    
                    <div
                        v-if="this.message.length > 0"
                        class="alert alert-danger"
                        role="alert"  >
                        {{ this.message }}
                    </div>
                  </Form>   
                </div>   
                
                 
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="profile.account.name">
      <strong>{{ profile.account.title }} {{ profile.account.name }}</strong>
      <div>
        <img :src="getImgPhoto(profile.account)" />
      </div>
      <p>
        <router-link :to="'/accounts/' + profile.id" class="badge badge-success"
          >{{ $t('edit') }}</router-link
        >
      </p>

      <p v-tooltip="`${$t('delete_profile_tip')}`">
        <span @click="deleteAccount(profile.id)"
          ><router-link to="/login" class="badge badge-danger"
            >{{ $t('delete_my_profile') }}</router-link
          ></span
        >
      </p>      
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { PET_DELETE, NEED_DELETE, PET_NEED } from '/static/actions/pet'
import { ACCOUNT_DELETE } from '/static/actions/user'
import datepicker from 'vue3-datepicker'
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
import { required, min} from '@vee-validate/rules';

defineRule('required', required);
defineRule('min', min);


export default {
  name: 'account',
  components: { datepicker , Form, Field, ErrorMessage},

  data() {
    return {
      showForm: false,
      message: '',
      currentPet: {},
      
      title: '',
      vacation_bid_price: 0,
      to: '',
      from: '',
      petid: '',
    }
  },
  computed: {
    ...mapGetters(['isGiver', 'isBoth']),
    ...mapState({
      profile: (state) => state.user.profile
    }),
    
  },
  created() {
    this.message = ''
    //console.log('created getError()', this.getError())
  },
  methods: {
    ...mapActions(['formatDate']),
    getError() {
      //console.log('getError()', this.$store.state.profiles.message)
      return this.$store.state.profiles.message
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
    cancelUpdate(curPet) {
      this.message = '',
      this.$store.commit('setMessage', this.message)
      this.showForm = false
      //console.log('cancel() this.title', this.title)
      curPet.need.title = this.title
      curPet.need.from_date = this.from
      curPet.need.to_date = this.to
      curPet.need.vacation_bid_price = this.vacation_bid_price
      this.title = ''
      this.from = ''
      this.to = ''
      this.vacation_bid_price = ''  
      //console.log('cancel()', this.currentPet.title)
      //this.pets = this.$store.state.user.profile.pets
    },
    async deleteAccount(id) {
      //console.log('profile.id', profile.id)
      await this.$store
        .dispatch(ACCOUNT_DELETE, id)
        .then(() => {})
        .catch(function (error) {
          console.log(error)
        })
    },  
    async deletePet(pet) {
      //console.log('pet.id', pet.id)
      await this.$store
        .dispatch(PET_DELETE, pet)
        .then(() => {})
        .catch(function (error) {
          console.log(error)
        })
    },
    deleteNeed(pet) {
      let petCur = {
        owner_id: pet.owner_id,
        pet_id: pet.id,
      }
      this.$store.dispatch(NEED_DELETE, petCur)
    },
    editNeed(pet) {
      console.log('editNeed')
      this.message = ''
      this.currentPet = pet
       
      this.currentPet.need.from_date = new Date(pet.need.from_date)
      this.currentPet.need.to_date = new Date(pet.need.to_date)

      this.title = pet.need.title
      this.from = this.currentPet.need.from_date
      this.to = this.currentPet.need.to_date
      this.vacation_bid_price = pet.need.vacation_bid_price

      this.showForm = true

            //pet.need.need_id = undefined
      //console.log('this.profile.pets', this.profile.pets)
    },
    newNeed(pet) {
      this.currentPet = pet
      this.currentPet.need = {
        owner_id: pet.owner_id,
        title: '',
        from_date: '',
        to_date: '',
        vacation_bid_price: 0,
      }
      this.$store.commit('setMessage', '')
      this.showForm = true
       
    },
    checkDates(from_date, to_date) {
      //console.log('checkDates from_date to_date', from_date, to_date)
      if (!from_date || from_date === "NaN-NaN-NaN") {
        //console.log('error dates', 'Empty dates') ;
        return false
      }
      if (!to_date || to_date === "NaN-NaN-NaN") {
        //console.log('error dates', 'Empty dates') ;
        return false
      }
      if (new Date(from_date).getTime() <= new Date().getTime()) {
        //console.log('error dates', 'From date is less than today') ;
        return false
      }
      if (new Date(from_date).getTime() == new Date(to_date).getTime()) {
        //console.log('Wrong dates');
        return false
      }
      if (new Date(from_date).getTime() > new Date(to_date).getTime()) {
        //console.log('Wrong dates');
        return false
      }
      return true
    },
    async addNeed(pet) {
      //console.log('addNeed title', pet.need.title)
      if (!pet.need.title) {
        //console.log('addNeed ', pet.need.title)
        this.message = this.$t('description_need_req')
        //this.$store.commit('setMessage', this.message)
        return false
      }
      if (!this.checkDates(pet.need.from_date, pet.need.to_date)) {
        //console.log('addNeed checkDates')
        this.message = this.$t('wrong_dates')
        this.showForm = true
        return false
      }
      
      this.message = ''
      //console.log('addNeed Promise from', pet.need.from_date)
      new Promise((resolve) => {
        this.$store.dispatch(PET_NEED, { pet })
        .then(() => {
          //console.log('addNeed else', this.getError())
          this.message = this.getError()
          if (this.message.length !== 0) {
            return false
          }  
        })
        .then(() => {
          if (this.message.length === 0) {
          //this.currentPet = {}
            this.title = ''
            this.to = ''
            this.from = ''
            this.showForm = false
            this.vacation_bid_price = 0
            resolve()
          }
        })
        .catch(() => {
            // commit('setError', error);
            //console.log('Invalid request ' + error)
        })
      })

       
    },
    getNeed() {
      //console.log('pet.need', pet.need)
    },
    getImgPhoto(obj) {
      return obj.photo
    },
  },
}
</script>
<style>
.blue-color {
  color: blue;
}
.green-color {
  color: green;
}

.red-color {
  color: red;
}
</style>
