<template>
  <div class="col-md-12">
    <div class="card card-container">
    <p><strong>{{ $t('edit_your_pet_profile') }}</strong></p>
      <Form
        @submit="updatePet(currentPet)"
        ref="petForm"
      >
        <div class="form-group">
          <label for="name"><strong>{{ $t('pet_name') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </label>
          <Field :validateOnChange="true" :validateOnInput="true"
            name="name"
            type="text"
            class="form-control"
            v-model="currentPet.name" rules="required|min:2"
          />
          <ErrorMessage name="name" class="error-feedback" />
        </div>

        <div class="form-group">
          <label for="interests"><strong>{{ $t('pet_interests') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </label>
          <Field :validateOnChange="true" :validateOnInput="true"
            name="interests"
            type="text"
            class="form-control"
            v-model="currentPet.interests" rules="required|min:3"
          />
          <ErrorMessage name="interests" class="error-feedback" />
        </div>

        <div class="form-group">
          <label for="physical_characteristics"><strong>{{ $t('physical_characteristics') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </label>
          <Field :validateOnChange="true" :validateOnInput="true"
            name="physical_characteristics"
            type="text"
            class="form-control"
            v-model="currentPet.physical_characteristics" rules="required|min:3"
          />
          <ErrorMessage
            name="physical_characteristics"
            class="error-feedback"
          />
        </div>

        <div class="form-group">
          <label for="needs"><strong>{{ $t('needs') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </label>
          <Field :validateOnChange="true" :validateOnInput="true"
            name="needs"
            type="text"
            class="form-control"
            v-model="currentPet.needs" rules="required|min:3"
          />
          <ErrorMessage name="needs" class="error-feedback" />
        </div>

        <div class="form-group">
          <span name="photo"  v-tooltip="`${$t('profile_photo_pet_tip')}`.concat(' ').concat(this.allowableTypes.join('|').toUpperCase())"><strong>{{ $t('photo') }}</strong></span> 
          <photo-upload ref="uploadPhotos" @changeMessage="changeFromChild($event)" />
        </div>
        <div class="form-group">
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancelUpdate" >
          {{ $t('cancel') }}
        </button>

        <button type="submit" class="badge badge-success" style="font-size: .87em;">{{ $t('update') }}</button>
        </div>
            <div
              v-if="getError().length > 0"
              class="alert alert-danger"
              role="alert"
            >
              {{ getError() }}
            </div>
      </Form>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import PhotoUpload from '../core/PhotoUpload'
import { directive } from 'vue-tippy'
import { required, min} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);



export default {
  name: 'PetProfileEdit',
  components: { PhotoUpload, Form, Field, ErrorMessage },
  directives: {
    tippy: directive,
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      pets: (state) => state.user.profile.pets,
    }),
    countries() {
      return [this.$t('Ukraine'), this.$t('Switzerland'), this.$t('Germany'), this.$t('Spain')]
    },
    cities() {
      return this.$store.state.product.cities
    },
    writeMessage: {
      // getter
      get() {
        return this.message
      },
      //setter
      set(newValue) {
        this.message = newValue
      },
    },
  },

  data() {
    return {
      currentPet: null,
      id: null,
      file: null,
      photos: [],
      maximumSize: 1000000,
      message: '',
      allowableTypes: ['jpg', 'jpeg', 'png', 'gif'],
    }
  },
  methods: {
    ...mapActions(['updatePet', 'deletePet', 'cancelUpdate']),
    changeFromChild(event) {
      this.message = event
      this.$store.commit('ERROR', event)
    },
    getError() {
      return this.$store.state.profiles.message
    },    
    updateCountry($event) {
      this.$store.commit('setSelectedCountry', $event.target.value)
      this.currentPet.location.country = $event.target.value
      this.$store.dispatch('getCities').then(() => {
      })
    },
    updateCity($event) {
      // this.$store.commit('setSelectedCity', $event.target.value)
      this.currentPet.location.city = $event.target.value
    },
    async updatePet(pet) {
      let photos = []
      if (!this.currentPet.interests || this.currentPet.interests === '') {
        this.message = this.$t('please_fill_out_fields')
        return false
      }
      if (
        this.$refs.uploadPhotos.photos &&
        this.$refs.uploadPhotos.photos.length > 0
      ) {
        photos = photos.concat(this.$refs.uploadPhotos.photos)
        pet.filedata = photos[0].data
        pet.filename = photos[0].name
        //this.validate(photos[0].name)
      }
      await this.$store
        .dispatch('updatePet', pet)

        .then(() => {})
        .catch(function (error) {
          console.log(error)
        })
    },

  },
  created() {
    this.message = ''
    this.$store.commit('ERROR', '')
    const id = this.$route.params.id
    this.currentPet = this.$store.state.user.profile.pets.filter(
      (pet) => pet.id == id
    )[0]

    this.$store.commit('setSelectedCountry', this.currentPet.location.country)
    this.$store.dispatch('getCities').then(() => {
    })
  },
}
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
