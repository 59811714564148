import axios from 'axios'
import router from '@/router'
import i18n from '@/i18n'
import { PET_DELETE, PET_NEED, NEED_DELETE } from '/static/actions/pet'
import { ACCOUNT_DELETE} from '/static/actions/user'
import { AUTH_LOGOUT } from '/static/actions/auth'

const state = { status: '', profile: {}, pets: [], message: '' }

const mutations = {
  UPDATE_PROFILE(state, payload) {
    localStorage.removeItem('profile')
    localStorage.setItem('profile', JSON.stringify(payload.profile))
    state.profile = JSON.parse(localStorage.getItem('profile'))
    state.message = ''
  },
  UPDATE_PET(state, payload) {
    state.pets = JSON.parse(JSON.stringify(payload.profile.pets))
    state.message = ''
  },
  ERROR(state, payload) {
    state.message = payload
  },
  setMessage(state, event) {
    state.message = event
  },  
}

const getters = {
  pets: (state) => state.pets,
  profile: (state) => state.profile,
  message: (state) => state.message,
}

const actions = {
  getProfile: ({ rootState }) => {
    return new Promise((resolve) => {
      state.profile = rootState.user.profile
      //state.pets = rootState.user.profile.pets;
      // console.log("SET_PROFILE pets", state.pets);
      resolve(state.profile)
    })
  },
  getPetNeed({ commit }, need) {
    axios.get('/api/pet/need', need).then((response) => {
      commit('UPDATE_PET_NEED', response.data)
    })
  },
  getPet: (id) => {
    return state.pets.find((pet) => pet.id === id)[0]
  },
  async sendEmail({ commit }, user) {

    await new Promise((resolve, reject) => {
      try {
        axios.post('/api/email', user).then((response) => {
          if ('success' === response.data.message) {
            commit('ERROR', '')
            resolve(true)
            router.push('/login')
          } else if ('success' !== response.data.message) {
            commit('ERROR', response.data.message)
            resolve(true)
          }
        })

        // return resp;
      } catch (error) {
        commit('ERROR', error)
        reject(error)
        // reject(err);
      }
    })
  },
  changePassword({ commit }, user) {
    axios.post('/api/changepswd/' + JSON.stringify(user)).then((response) => {
      if (response.data === '') {
        commit('ERROR')
      } else {
        state.message = ''
        router.push('/login')
      }
    })
  },
  updateProfile({ commit, rootState }, profile) {
    let authToken = rootState.auth.token
    axios
      .post('/api/profile', profile, { headers: { Authorization: authToken } })
      .then((response) => {
        if ( response.data === 'Please use another photo') {   
          commit('ERROR', i18n.global.t('use_another_photo'))
        }
        else if ( response.data === 'Please ask admin help') { 
          commit('ERROR', i18n.global.t('ask_admin_help'))
        } else {
          commit('UPDATE_PROFILE', response.data)
          rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
          router.push('/account')
        }
      })
  },
  cancelUpdate({ commit, rootState }) {
    commit('ERROR', '')
    commit('setMessage', '')
    rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
    router.push('/account')
  },
  async updatePet({ commit, rootState }, pet) {
    // let authToken = rootState.auth.token;
    await new Promise((resolve, reject) => {
      try {
        axios.post('/api/pet', pet).then((response) => {
          if (response.data && response.data.message) {
            if ('Server Error' === response.data.message) {
              router.push('/error')
            } else commit('ERROR', i18n.global.t('use_another_photo'))
          }
          // if (response.data === 'Please use another photo' || response.data === 'Please ask admin help') {
          //   commit('ERROR', response.data)
          // }
          else {
            commit('UPDATE_PROFILE', response.data)
            commit('UPDATE_PET', response.data)
            rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
            router.push('/account')
            resolve(true)
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  },
  addPetProfile({ commit, rootState }, pet) {
    axios.post('/api/pet', pet).then((response) => {
      commit('UPDATE_PROFILE', response.data)
      rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
      router.push('/account')
    })
  },
  [NEED_DELETE]: ({ commit, rootState }, pet) => {

    axios.delete('/api/delete/need/' + JSON.stringify(pet)).then((response) => {
      commit('UPDATE_PROFILE', response.data)
      commit('UPDATE_PET', response.data)
      rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
      router.push('/account')
    })
  },
  [ACCOUNT_DELETE]: ({ dispatch }, id) => {

    axios.post('/api/delete/account/'+ JSON.stringify(id)).then(() => {

      dispatch(AUTH_LOGOUT)
      router.push('/login')
    })
  },      
  [PET_DELETE]: ({ commit, rootState }, pet) => {
    const postdata = { pet_id: pet.id, owner_id: pet.owner_id }
    axios.post('/api/delete/pet/', postdata).then((response) => {
      commit('UPDATE_PROFILE', response.data)
      commit('UPDATE_PET', response.data)
      rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
      router.push('/account')
    })
  },
  async [PET_NEED]({ commit, rootState }, pet) {
    // console.log('JSON.stringify(pet)', JSON.parse(JSON.stringify(pet)).pet.need);
    var need = JSON.parse(JSON.stringify(pet)).pet.need
    var pt = JSON.parse(JSON.stringify(pet)).pet
    // axios.post('/api/pet/need/'+ JSON.stringify(pet)).then((response) => {
    const dateF =
      new Date(need.from_date).getFullYear() +
      '-' +
      (new Date(need.from_date).getMonth() + 1) +
      '-' +
      new Date(need.from_date).getDate()
    const dateT =
      new Date(need.to_date).getFullYear() +
      '-' +
      (new Date(need.to_date).getMonth() + 1) +
      '-' +
      new Date(need.to_date).getDate()
    if (need.need_id === undefined) {
      need.need_id = 1
    }
    const postdata = {
      pet_id: pt.id,
      owner_id: pt.owner_id,
      need_id: need.need_id,
      need_price: need.vacation_bid_price,
      need_title: need.title,
      need_from_date: dateF,
      need_to_date: dateT,
    }
    await new Promise((resolve, reject) => {
      try {
        axios.post('/api/pet/need/', postdata).then((response) => {
          if ('Wrong dates' === response.data.message) {
            commit('ERROR', i18n.global.t('wrong_dates'))
            resolve(false)
          } else if ('Wrong dates' !== response.data.message) {
            commit('UPDATE_PROFILE', response.data)
            commit('UPDATE_PET', response.data)
            rootState.user.profile = JSON.parse(localStorage.getItem('profile'))
            resolve(true)
            router.push('/account')
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  },
}

const profileModule = {
  state,
  mutations,
  getters,
  actions,
}

export default profileModule
