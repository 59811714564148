<template>
  <div >
        <router-link :to="'/animals/pet/'+ pet.id" style="margin-right: 10px;">
          <strong>{{ pet.name }} </strong>
        </router-link> 
  </div>      
  <div >     
    <img :src="getImgPhoto(pet)" />
  </div>
  

</template>
<script>
// import {mapActions} from 'vuex'
export default {
  name: 'Pet',
  props: ['pet', 'owner'],
  methods: {
    // ...mapActions(["addCartItem"]),
    getImgPhoto(obj) {
      return obj.photo
    },
  },
  created() {
    //this.owner = this.$store.dispatch('getOwner', pet);
    //this.$store.commit('setPet', this.pet)
    //localStorage.setItem('pet', this.pet)
  },
}
</script>
