<template>
  <div class="card card-container">
  <p><strong>{{ $t('add_your_pet_profile') }}</strong></p>
    <div v-if="!submitted" class="col-md-12">
      <Form
        @submit="addPetProfile"
        :initial-values="pet"
        ref="petForm"
      >
        <div v-if="!successful">
          <span name="photo"  v-tooltip="`${$t('profile_photo_pet_tip')}`.concat(' ').concat(this.allowableTypes.join('|').toUpperCase())"><strong>{{ $t('photo') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </span> 
          <photo-upload ref="uploadPhotos" @changeMessage="changeFromChild($event)" />
          <div class="form-group">
            <label for="name"><strong>{{ $t('pet_name') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="name" :validateOnChange="true" :validateOnInput="true" type="text" class="form-control" rules="required|min:2" />
            <ErrorMessage name="name" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="interests"><strong>{{ $t('pet_interests') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="interests" type="text" class="form-control" :validateOnChange="true" :validateOnInput="true" rules="required|min:3" />
            <ErrorMessage name="interests" class="error-feedback" />
          </div>

          <div class="form-group">
          <label for="physical_characteristics"><strong>{{ $t('physical_characteristics') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
          </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="physical_characteristics"
              type="text"
              class="form-control" rules="required|min:3"
            />
            <ErrorMessage
              name="physical_characteristics"
              class="error-feedback"
            />
          </div>

          <div class="form-group">
            <label for="needs"><strong>{{ $t('needs') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="needs" type="text" class="form-control" :validateOnChange="true" :validateOnInput="true" rules="required|min:3"/>
            <ErrorMessage name="needs" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="role" v-tooltip="`${$t('kind_of_pet_tip')}`"><strong>{{ $t('kind_of_pet') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
            </span> 
            <p style="white-space: nowrap;">
            <Field name="kind" type="radio" :value="1" rules="required"/>
            <span class="radio-label" style="margin: 10px"><strong>{{ $t('dogs') }}</strong></span>

            <Field name="kind" type="radio" :value="2" rules="required"/>
            <span class="radio-label" style="margin: 10px"><strong>{{ $t('cats') }}</strong></span>
            </p>
            <p>
            <Field name="kind" type="radio" :value="3" rules="required"/>
            <span class="radio-label" style="margin: 10px"><strong>{{ $t('birds') }}</strong></span>

            <Field name="kind" type="radio" :value="4" rules="required"/>
            <span class="radio-label" style="margin: 10px"><strong>{{ $t('other') }}</strong></span>
            <ErrorMessage name="kind" class="error-feedback" />
            </p>
          </div>

          <div class="form-group">
            <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancelUpdate">
              {{ $t('cancel') }}
            </button>
            <button type="submit" class="badge badge-success" style="font-size: .87em;">{{ $t('new_pet') }}</button>
          </div>
          <div
              v-if="getError().length > 0"
              class="alert alert-danger"
              role="alert"
          >
              {{ getError() }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import i18n from '@/i18n'
import { directive } from 'vue-tippy'
import { required, min} from '@vee-validate/rules';

import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);
import PhotoUpload from '../core/PhotoUpload'

export default {
  name: 'PetProfile',
  components: {
    Form,
    Field,
    ErrorMessage,
    PhotoUpload,
  },
    directives: {
    tippy: directive,
  },
  data() {
    const pet = {
      id: null,
      kind: '',
      name: '',
      interests: '',
      needs: '',
      physical_characteristics: '',
      owner_id: this.$store.state.user.profile.id,
      location: {
        country: this.$store.state.user.profile.account.country,
        city: this.$store.state.user.profile.account.city,
      },
    }
    return {
      profile: this.$store.state.user.profile,
      pet,
      submitted: false,
      successful: false,
      message: '',
      allowableTypes: ['jpg', 'jpeg', 'png', 'gif'],
    }
  },
  computed: {
    cities() {
      return this.$store.state.product.cities
    },
  },
  methods: {
    ...mapActions(['cancelUpdate']),
    changeFromChild(event) {
      this.message = event
      this.$store.commit('ERROR', event)      
    },
    getError() {
      return this.$store.state.profiles.message
    }, 
    async addPetProfile(pet) {
      let photos = []

      if (
        this.$refs.uploadPhotos.photos &&
        this.$refs.uploadPhotos.photos.length == 0
      ) {

        this.message += this.$t('profile_photo')
        return
      }
      this.validate(this.$refs.uploadPhotos.photos[0].name)
      if (
        this.$refs.uploadPhotos.photos &&
        this.$refs.uploadPhotos.photos.length > 0
      ) {
        photos = photos.concat(this.$refs.uploadPhotos.photos)
        pet.filedata = photos[0].data
        pet.filename = photos[0].name
      }
      await this.$store
        .dispatch('updatePet', pet)

        .then(() => {
          // this.successful =
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    validate(name) {
      if (!this.allowableTypes.includes(name.split(".").pop().toLowerCase())) {
        this.message = i18n.global.t('image_types_expected') + this.allowableTypes.join("|").toUpperCase() + ' files.';
        return false
      }

      return true
    },           
  },
  created() {
    this.message = ''
    this.$store.commit('ERROR', '')
  },  

}
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
