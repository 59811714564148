<template>
  <span>
  <p style="white-space: nowrap;"><strong>{{ $t('pet') }}:  {{ pet.name }} </strong></p>
  </span>
  <div class="row">
    
    <div class="col">
      
      
      <p><strong>{{ $t('needs') }}:</strong> {{ pet.need.title }}</p>
      <p><strong>{{ $t('date_for_home') }}:</strong> </p>
      <p><strong>{{ $t('from') }}: </strong><span style="white-space: nowrap;">{{ pet.need.from_date }}</span></p>
      <p> <strong>{{ $t('to') }}:  </strong><span style="white-space: nowrap;">{{ pet.need.to_date }}</span></p>
      <p><strong>{{ $t('service_costs') }}</strong>: {{ pet.need.vacation_bid_price }}</p>      
      
    </div>
    <div class="col">
      <p><strong>{{ $t('country') }}:</strong> {{ pet.location.country }}</p>
      <p><strong>{{ $t('city') }}:</strong> {{ pet.location.city }}</p>
      <p><strong>{{ $t('interests') }}: </strong>{{ pet.interests }}</p>
      <p><strong>{{ $t('physical_characteristics') }}: </strong>{{ pet.physical_characteristics }}</p>
    </div>
  </div>
  <div>
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
  </div>
</template>
<script>

export default {
  name: 'ProfilePet',
  data() {
    return {
      pet: {
        need: {
          title: ''
        },
        location: {},
      },
    }
  },  
  methods: {

    cancel() {
      this.$router.go(-1)
    },    
  },
  created() {
    
    let id = this.$route.params.id
    this.$store.dispatch('getPetById', { id }).then(() => {
      this.pet = this.$store.state.product.pet
    })    

  },
}
</script>
