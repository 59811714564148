<template>
  <div class="row">
    <div class="col">
      <p ><strong>{{ $t('owner') }}</strong></p>
       <p style="white-space: nowrap;"><strong>{{ owner.title }} {{ owner.name }}</strong></p>
      <img :src="getImgPhoto(owner)" />
      <p><strong>{{ $t('interests') }}: </strong>{{ owner.interests }}</p>
    </div>
    <div class="col">
      <p><strong>{{ $t('contacts') }}</strong></p>
      <p><strong>{{ $t('email') }}: </strong>{{ owner.email }}</p>
      <p><strong>{{ $t('phone') }}: </strong>{{ owner.phone }}</p>
    </div>
  </div>
  <div>
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ProfileContact',
  computed: mapState({ owner: (state) => state.product.owner }),
  methods: {
    // ...mapActions(["addCartItem"]),
    getImgPhoto(obj) {
      return obj.photo
    },
    cancel() {
      this.$router.go(-1)
    },    
  },
  created() {
    let id = this.$route.params.id
    this.$store.dispatch('getOwner', { id })
  },
}
</script>
