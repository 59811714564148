<template>
  <div class="col-md-12">
    <div class="card card-container">
      <Form @submit="sendEmail" >
        <div>
          <div class="form-group">
            <p><strong><label for="email">{{ $t('email_forget') }}</label></strong></p>
            <Field name="email" type="email" class="form-control" rules="required|email" :validateOnChange="true" :validateOnInput="true" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>

          <div class="form-group">
            <button class="btn btn-success btn-block">{{ $t('send') }}</button>
          </div>
        </div>
        <div class="form-group">
          <div v-if="getMessage()" class="alert alert-danger" role="alert">
            {{ getMessage() }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { required, email} from '@vee-validate/rules';

import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('email', email);


export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      
      message: '',
    }
  },
  methods: {
    getMessage() {
      return this.$store.state.profiles.message
    },
    async sendEmail(user) {
      try {
        const pr = await this.$store.dispatch('sendEmail', user)
        if (!pr) {
          //this.message = "Error, can not send to this email";
        }
      } catch (error) {
        console.log('sendEmail', error)
        throw new Error('Invalid request')
      }
    },
  },
}
</script>
