<template>
  <div v-if="Array.isArray(animals) && animals[0]">
    <div v-for="obj in pageOfItems" :key="obj.owner.id" >

      <div v-for="ar in obj.animals" :key="ar" class="card">
        <router-link :to="'/animals/owner/' + obj.owner.id">
          <p><strong>{{ $t('owner') }}: {{ obj.owner.title }} {{ obj.owner.name }}</strong></p>
        </router-link>        
        <Pet :pet="ar" />

      </div>
    </div>
    <div class="card" >
      <JwPagination :items="animals" @changePage="onChangePage"></JwPagination>
    </div>
  </div>

  <div v-else> {{ $t('not_found') }}  </div>
</template>
<script>
//import { mapGetters } from 'vuex';
import Pet from './Pet'
import i18n from '@/i18n'
// import NavbarPeople from '@/components/core/NavbarPeople';
import JwPagination from '../core/JwPagination'
export default {
  name: 'AnimalList',
  components: {
    // NavbarPeople,
    Pet,
    JwPagination,
  },
  data() {
    return {
      pageOfItems: [],
    }
  },
  computed: {
    //...mapGetters(['petChoice']),
    choice () {
      return this.choiceTranslate()
    },
    animals() {
      let pets = this.$store.state.product.animals
      return pets
    },
  },
  methods: {
    choiceTranslate() {
      let ch = this.$store.state.product.petChoice
      if (ch == 1) {
        return i18n.global.t('dogs')
      }
      else if (ch == 2) {
        return i18n.global.t('cats')
      }
      else if (ch == 3) {
        return i18n.global.t('birds')
      }
      else if (ch == 4) {
        return i18n.global.t('other')
      }
      return ''
    },  
    filterAnimalsAgain(pet) {
      return new Date(pet.need.from_date).getMonth() == new Date().getMonth()
    },
    filterAnimals(pet) {
      return (
        pet.need.vacation_bid_price <= this.$store.state.product.highPrice &&
        pet.location.city == this.$store.state.product.selectedCity &&
        pet.location.country == this.$store.state.product.selectedCountry
      )
    },

    getImgPhoto(obj) {
      return obj.photo
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
    currentWeekDate() {
      const current = new Date()
      const date =
        current.getFullYear() +
        '-' +
        (current.getMonth() + 1) +
        '-' +
        current.getDate()
      // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      // const dateTime = date +' '+ time;
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      current.setDate(new Date(date).getDate() - 7)
      return this.formatDate(current)
    },
  },
  created() {
    this.$store.commit('setSelectedCity', '')
    
  },
}
</script>

<style>
.card {
  background-color: #2ed87c5c !important;
}
</style>
